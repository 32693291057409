<template>
  <div class="complain rounded bg-secondary-3 p-2">
    <div class="row mx-2 mt-2 mb-3 align-items-center">
      <div class="col-12 col-md-3 text-center mb-2 mb-md-0 border-right">
        <span :class="`badge-status-${statusColor}`">
          {{ info.status | capitalize }}
        </span>
      </div>
      <div class="col-12 col-md-3 text-center mb-2 mb-md-0">
        <span class="font-weight-bold color-black">
          {{ info.complaintNumber }}
        </span>
      </div>
      <div class="col-12 col-md-2 mb-3 mb-md-0">
        <span class="data-title">Fecha:</span>
        {{ info.complaintDate }}
      </div>
      <div class="col-12 col-md-2 mb-3 mb-md-0">
        <span class="data-title">Región/Municipio:</span>
        {{ info.region_municipaly }}
      </div>
      <div class="col-12 col-md-2 text-right">
        <action-button
          i="fas fa-file-import"
          text="Entrar"
          variant="outline-success btn-block-xs-only"
          @click="enter"
        />
      </div>
    </div>
    <div class="row mx-0 bg-white p-3 px-md-5 rounded justify-content-between">
      <div class="col-12 col-md-3 mb-3 mb-md-0">
        <span class="data-title">Nombre del Abogado:</span>
        {{ info.lawyerDLEEName | capitalize }}
      </div>
      <div class="col-12 col-md-3 mb-3 mb-md-0">
        <span class="data-title">Nombre del Conciliador/Medidador:</span>
        {{ info.mechanism | capitalize }}
      </div>
      <div class="col-12 col-md-3 mb-3 mb-md-0">
        <span class="data-title">Nombre del Juez:</span>
        {{ info.judgeIndividualFullName | capitalize }}
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from 'ActionButton';
import complainStatus from '@/views/students/complaints-history/constans/complainStatus';
import statusColors from '@/utils/constants/statusColors';
import { capitalize } from '@/utils/filters';

export default {
  name: 'Complaint',
  components: { ActionButton },
  filters: { capitalize },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    statusColor() {
      switch (capitalize(this.info.status)) {
        case complainStatus.AdministrativeHearingProcess:
          return statusColors.Purple;
        case complainStatus.MeasurementProcess:
        case complainStatus.ConciliationProcess:
        case complainStatus.MediationProcess:
          return statusColors.Yellow;
        case complainStatus.WithDrawal:
        case complainStatus.Rejection:
          return statusColors.Brown;
        case complainStatus.TotalAgreement:
        case complainStatus.FinalResolution:
          return statusColors.GreenLight;
        case complainStatus.Amended:
          return statusColors.Orange;
        default:
          return null;
      }
    },
  },
  methods: {
    enter() {
      this.$router.push({
        name: 'student-complaints_followUp',
        params: { complaintId: this.info.complaintId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.complain {
  .data-title {
    display: block;
  }
  span[class^='badge-status-'] {
    padding: 0.5rem;
    min-width: 90%;
  }
}
@media only screen and (max-width: 768px) {
  .border-right {
    border-right: unset !important;
  }
}
</style>
