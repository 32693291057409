<template>
  <div class="complaint-history">
    <custom-result-not-found
      v-if="results.length === 0"
      text="Usted no cuenta con información en el sistema."
    />
    <template v-else>
      <complaint
        v-for="(row, idx) of results"
        :key="`complain-${idx}`"
        class="mb-3"
        :info="row"
      />
      <pagination
        v-if="pagination.totalItemCount > defaultPageSize"
        :data="pagination"
        show-text
        @pagination-go-page="goToPage"
      />
    </template>
  </div>
</template>

<script>
import Pagination from 'pagination';
import CustomResultNotFound from 'custom-result-not-found';
import Complaint from '@/views/students/complaints-history/components/Complaint.vue';
import { getComplaintsHistory } from '@/services/api/complaints.api';

export default {
  name: 'ComplaintsHistory',
  components: { Pagination, CustomResultNotFound, Complaint },
  props: {
    studentSieId: {
      type: Number,
      default: () => 0,
    },
    defaultPageSize: {
      type: Number,
      default: () => 10,
    },
  },
  data: () => ({
    pagination: {
      currentPage: 1,
      totalItemCount: 0,
      pageCount: 1,
      count: 0,
    },
    results: [],
  }),
  async created() {
    await this.goToPage(1);
  },
  methods: {
    async goToPage(page) {
      this.pagination.currentPage = page;

      const nextComplaintIdx =
        page == 1
          ? 0
          : this.pagination.currentPage * this.defaultPageSize -
            (this.defaultPageSize - 1);

      const { data: response } = await getComplaintsHistory(
        this.studentSieId,
        nextComplaintIdx,
        this.defaultPageSize
      );

      this.results = response.data.map((d) => ({
        complaintId: d.complaintId,
        status: d.status,
        complaintNumber: d.complaintNumber,
        complaintDate: d.complaintDate,
        region_municipaly: d.region_Municipaly,
        lawyerDLEEName: d.lawyerDLEEName,
        mechanism: d.mechanism,
        judgeIndividualFullName: d.judgeIndividualFullName,
      }));
      this.pagination = {
        ...this.pagination,
        totalItemCount: response.totalItemCount,
        pageCount: response.pageCount,
        count: response.count,
      };
    },
  },
};
</script>
