export default Object.freeze({
  AdministrativeHearingProcess: 'En Proceso Vista Administrativa',
  MeasurementProcess: 'En Proceso de Mediación',
  ConciliationProcess: 'En Proceso Conciliación',
  WithDrawal: 'Desistimiento',
  Rejection: 'Desestimación',
  TotalAgreement: 'Acuerdo Total',
  FinalResolution: 'Resolución Final',
  Amended: 'Enmendada',
  MediationProcess: 'En Proceso Mediación',
});
