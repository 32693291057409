<template>
  <div v-if="studentSieId">
    <page-header
      :actions="headerActions"
      :page="{
        icon: 'fas fa-file-contract',
        title: 'Historial de Querellas',
        routes: ['Inicio', 'Historial de Querellas'],
      }"
    />

    <complains-history :student-sie-id="Number(studentSieId)" />
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import ComplainsHistory from '@/views/students/complaints-history/components/ComplainsHistory.vue';

export default {
  name: 'ComplaintsHistory',
  components: { PageHeader, ComplainsHistory },
  props: { studentSieId: { type: [String, Number], default: null } },
  computed: {
    headerActions() {
      return [
        {
          text: 'Regresar',
          icon: 'fas fa-arrow-circle-left fa-fw',
          variant: 'outline-success',
          click: () => this.$router.push({ name: 'home' }),
        },
        {
          text: 'Radicar Querella',
          icon: 'fas fa-file-user',
          variant: 'success',
          click: () => this.$router.push({ name: 'student-complaints_filing' }),
        },
      ];
    },
  },
};
</script>
